import React from "react";
import AccountCard from "./accountCard";
import MovementsCard from "./movementsCard";

const CardHeader = (props) => {
	const { stockings, account, accountValue, unprocessedTransactions, handleAccountCreate } = props;
	return (
		<div className="row px-3 gy-4">
			<div className="p-0 col-sm-6 col-lg-4 pe-sm-2 pe-md-4 pe-lg-2 pe-xl-5">
				<AccountCard account={account} accountValue={accountValue} handleAccountCreate={handleAccountCreate} />
			</div>
			<div className="p-0 col-sm-6 col-lg-4 ps-sm-2 ps-md-4 ps-lg-3 ps-xl-2 pe-sm-0 pe-xl-5">
				<MovementsCard stockings={stockings} account={account} unprocessedTransactions={unprocessedTransactions} />
			</div>
			<div className="col-sm-0 col-lg-4 px-0 ps-lg-4 ps-xl-3 pe-xl-0">{/* Nothing in Here  */}</div>
		</div>
	);
};

export default CardHeader;
