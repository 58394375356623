import { useContext } from "react";
import ApplicationContext from "../../services/applicationContext";

const AccountSelector = (props) => {
	const context = useContext(ApplicationContext);
	const { showAccountForm } = context;
	const { accounts, selected, onChange } = props;

	const getClasses = (selected) => {
		// if (selected) return "nav-link text-light disabled";
		// else return "nav-link text-dark";

		if (selected) return "nav-link text-light bg-primary border-primary text-truncate";
		else return "nav-link text-dark  text-truncate";
	};

	const getStyles = (selected) => {
		// if (selected) return { backgroundColor: "grey", borderColor: "grey" };
		// else return { backgroundColor: "lightgray", borderColor: "lightgray" };

		if (selected) return { maxWidth: "38vw" };
		else return { backgroundColor: "lightgray", borderColor: "lightgray", maxWidth: "38vw" };
	};

	return (
		<div className="mt-3 border-bottom border-dark-subtle px-1">
			<ul className="nav nav-tabs">
				{accounts &&
					accounts.map((account, idx) => (
						<li key={idx} className="nav-item  me-1">
							<button
								className={getClasses(idx === selected)}
								aria-current="page"
								href="#"
								style={getStyles(idx === selected)}
								onClick={() => onChange(idx)}
							>
								{account.name}
							</button>
						</li>
					))}

				<li className="nav-item me-1">
					<div title="Neues Basiskonto">
						<button className={getClasses(false)} href="#" style={getStyles(false)} onClick={showAccountForm}>
							+
						</button>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default AccountSelector;
