import { Search, XLg } from "react-bootstrap-icons";
import "../../css/filterBox.css";
import React, { Component } from "react";

class FilterBox extends Component {
	inputRef = React.createRef();

	toggle = () => {
		const { onFilterUpdate } = this.props;
		this.setState({ open: !this.state.open }, () => {
			setTimeout(() => {
				if (!this.state.open) onFilterUpdate("");
				else this.inputRef.current.focus();
			}, 20);
		});
	};

	handleKeyUp = (event) => {
		if (event.key === "Enter") {
			const { filter } = this.props;
			if (filter === "") this.toggle();
			this.inputRef.current.blur();
		} else if (event.key === "Escape") {
			this.toggle();
		}
	};

	state = {
		open: false,
	};

	render() {
		const { filter, onFilterUpdate } = this.props;
		const { open } = this.state;

		if (!open)
			return (
				<button className="btn btn-outline-primary" onClick={() => this.toggle()}>
					<Search size="16px" style={{ marginTop: "-1px" }} />
				</button>
			);

		return (
			<div className="filter-box-border">
				<Search size="19px" className="text-primary ms-2 me-2" />
				<input
					ref={this.inputRef}
					type="text"
					className="form-control"
					value={filter}
					onKeyUp={this.handleKeyUp}
					onChange={(event) => onFilterUpdate(event.target.value)}
					placeholder="Strumpfname"
				/>
				<XLg className="text-danger me-1 clickable" onClick={() => this.toggle()} />
			</div>
		);
	}
}

export default FilterBox;
