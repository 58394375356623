import React from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import ApplicationContext from "../../services/applicationContext";
import Users from "../../services/userService";
import ResponsiveButton from "../common/forms/responsiveButton";

class ForgotPasswordFormComponent extends Form {
	static contextType = ApplicationContext;
	state = {
		// @Override
		data: {
			username: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.passwordForgottenFormSchema;

	// @Override
	doSubmit = async () => {
		try {
			await Users.forgotPassword(this.state.data);
			this.props.history.push("/vergessen/danke");
		} catch (ex) {
			Users.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<Input
						label="E-Mail"
						name="username"
						error={errors.username}
						type="text"
						placeholder=""
						value={data.username}
						onChange={this.handleChange}
					/>
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}

					<ResponsiveButton
						label="Absenden"
						color="primary"
						hint="Fordert eine E-Mail zum Zurücksetzen Deines Passwortes an."
						hasLicense={true}
						disabled={this.validate()}
						disabledHint="Bitte fülle zunächst das Formular aus."
					/>

					<div className="form-text mt-2">
						Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
					</div>
				</form>
			</div>
		);
	}
}

const ForgotPasswordForm = withRouter(ForgotPasswordFormComponent);

export default ForgotPasswordForm;
