export function generateMonthArray(minus, plus) {
	const currentMonth = new Date();
	const thisMonth = currentMonth.getMonth();
	currentMonth.setDate(1);
	currentMonth.setHours(0);
	currentMonth.setMinutes(0);
	currentMonth.setSeconds(0);

	const months = [];
	for (let i = minus; i < plus; i++) {
		const d = new Date(currentMonth);
		d.setMonth(i + thisMonth);
		months.push(d);
	}
	return months;
}

export function isSameMonth(date1, date2) {
	return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export function isFuture(month) {
	return new Date().getTime() < month.getTime();
}

export function getMonth(date1) {
	const month = new Date(date1);
	month.setDate(1);
	month.setHours(0);
	month.setMinutes(0);
	month.setSeconds(0);
	return month;
}

export function monthDiff(date1, date2) {
	var months;
	months = (date2.getFullYear() - date1.getFullYear()) * 12;
	months -= date1.getMonth();
	months += date2.getMonth();
	return months <= 0 ? 0 : months;
}

// Adding addMonth functionality to Date objects
/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.isLeapYear = function (year) {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

Date.getDaysInMonth = function (year, month) {
	return [31, Date.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
	return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
	return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
	var n = this.getDate();
	this.setDate(1);
	this.setMonth(this.getMonth() + value);
	this.setDate(Math.min(n, this.getDaysInMonth()));
	return this;
};
