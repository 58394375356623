import React from "react";
import ListCard from "../common/listCard";
import Format from "../../services/formattingService";
import Validation from "../../services/validation";

const UserCard = (props) => {
	const { user } = props;

	const items = [
		{
			label: "Name",
			value: user ? user.name : null,
			editable: true,
			name: "name",
			validationSchema: Validation.getFieldSchema("registerFormSchema", "name"),
		},
		{
			label: "E-Mail",
			value: user ? user.email : null,
			editable: false /*,	name: "email",	validationSchema: Validation.getFieldSchema("registerFormSchema", "email"),*/,
		},
		{ label: "registriert seit", value: user ? Format.datetime(new Date(user.enrollmentDate)) : null, editable: false },
	];
	// const link = {
	// 	label: "Bearbeiten",
	// 	onClick: null,
	// };

	const handleEdit = (data) => {
		const { handleUpdate } = props;
		data.value = data.value.trim();

		if (user[data.name] !== data.value) {
			handleUpdate(data);
		}
	};

	return <ListCard title="Benutzerdaten" items={items} onEdit={handleEdit} />;
};

export default UserCard;
