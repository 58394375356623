import http from "./httpService";

async function createUser(userData) {
	return http.post("users", userData);
}

async function getCurrentUser() {
	try {
		const { data: user } = await http.get("users/me");
		return user;
	} catch (error) {
		return null;
	}
}

async function updateCurrentUser(changes) {
	const { data: user } = await http.patch("users/me", changes);
	return user;
}

async function verifyUser(token) {
	return http.get("users/verify", { params: { token } });
}

async function forgotPassword(data) {
	return http.post("users/forgot", data);
}

async function resetPassword(data) {
	return http.post("users/reset", data);
}

function getJwt() {
	return sessionStorage.getItem("token");
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const userService = {
	createUser,
	getCurrentUser,
	updateCurrentUser,
	verifyUser,
	forgotPassword,
	resetPassword,
	getJwt,
	handleExpectedErrors,
};

export default userService;
