import { useState } from "react";
import Input from "./forms/input";
import { useEffect } from "react";

const TextWithConfirmationBody = (props) => {
	const { text, onConfirmation, confirmationKeyword } = props;
	const [confirmation, setConfirmation] = useState("");

	useEffect(() => {
		onConfirmation(confirmation === confirmationKeyword);
	}, [confirmation, confirmationKeyword, onConfirmation]);

	return (
		<>
			<p>{text}</p>
			<p>Zum Fortfahren gib bitte das Wort {confirmationKeyword} ein:</p>
			<Input
				//label="Sicherheitsabfrage"
				placeholder={confirmationKeyword}
				name="confirmation"
				//error={errors.name}
				type="text"
				value={confirmation}
				onChange={(e) => setConfirmation(e.target.value)}
			/>
		</>
	);
};

export default TextWithConfirmationBody;
