import React, { Component } from "react";
import Users from "../services/userService";

class Verification extends Component {
	state = {};

	async componentDidMount() {
		const token = this.props.match.params.token;
		if (token && token.trim().length > 0) {
			try {
				await Users.verifyUser(token);
				this.props.history.push("/aktivieren/danke");
			} catch (ex) {
				Users.handleExpectedErrors(ex, this);
			}
		} else {
		}
	}

	render() {
		const { errorMessage } = this.state;
		return (
			<React.Fragment>
				<div className="row mx-3">
					<div className="col-md-2 col-lg-3 col-xl-3"></div>
					<div className="col-md-8 col-lg-6 col-xl-6 mb-5">
						<center>
							<h1 className="mt-5">Aktivierung</h1>
							<p className="mt-5 lead">Wir aktivieren Dein Konto, einen Moment bitte...</p>
							{errorMessage && (
								<div className="alert alert-danger mt-5" role="alert">
									{errorMessage}
								</div>
							)}
						</center>
					</div>
					<div className="col-auto"> </div>
				</div>
			</React.Fragment>
		);
	}
}

export default Verification;
