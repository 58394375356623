import React, { Component } from "react";
//import { toast } from "react-toastify";
//import { Gear } from "react-bootstrap-icons";
import { BoxArrowInRight, BoxArrowRight } from "react-bootstrap-icons";
import _ from "lodash";
import Table from "../common/table";
import Format from "../../services/formattingService";
import ColorPickerPreset from "../common/forms/colorPickerPreset";
//import ResponsiveButton from "../common/forms/responsiveButton";
import StockingService from "../../services/stockingService";
import RecurringService from "../../services/recurringService";
import ApplicationContext from "../../services/applicationContext";

class RecurringManagement extends Component {
	static contextType = ApplicationContext;

	getColumns = (table, currency) => {
		const columns = [
			{
				label: "Sparstrumpf",
				path: "stockingName",
				content: (d) => (
					<div className="d-flex align-items-center">
						<ColorPickerPreset color={d.stockingColor || "#23ac4f"} selected={false} /> {d.stockingName}
					</div>
				),
			},
			{ label: "Beschreibung", path: "description" },
			{
				label: "Typ",
				path: "type",
				content: (d) => (
					<React.Fragment>
						{d.type === "deposit" ? (
							<div title="Einzahlung">
								<BoxArrowInRight className="me-2 text-secondary" size="22" />
							</div>
						) : (
							<div title="Auszahlung">
								<BoxArrowRight className="ms-2 me-1 text-secondary" size="20" />
							</div>
						)}
					</React.Fragment>
				),
			},
			{ label: "ab", path: "startDate", content: (d) => <React.Fragment>{Format.date(new Date(d.startDate))}</React.Fragment> },
			{
				label: "bis",
				path: "endDate",
				content: (d) => <React.Fragment>{d.endDate && Format.date(new Date(d.endDate))}</React.Fragment>,
			},
			{ label: "Intervall", path: "interval", content: (d) => RecurringService.intervalTypes.find((i) => i.value === d.interval.toString()).label },
			{ label: "Betrag", align: "end", path: "value", content: (d) => Format.money(d.value, currency) },

			//{ label: "Saldo", align: "end", path: "balance", content: (d) => (d.hasOwnProperty("balance") ? Format.money(d.balance, currency) : <Spinner />) },
		];

		if (table === "active") {
			// add further columns for the active table
			return [
				...columns,
				/*{
					label: "",
					path: "stockingId",
					align: "end",
					content: (d) => (
						<ResponsiveButton
							label="Bearbeiten"
							color="primary"
							hint="Öffnet das Dialogfeld zum Bearbeiten dieses Sparstrumpfes."
							hasLicense={true}
							disabled={!d.hasOwnProperty("balance")}
							disabledHint="Für diese Funktion muss noch der Saldo des Sparstrumpes abgefragt werden."
							className="me-2"
							onClick={() => this.handleEdit(d)}
						/>
					),
				},*/
			];
		}
	};

	getNarrowTableColumns = (table, currency) => {
		const columns = [
			{
				label: "Sparstrumpf / Beschreibung",
				path: "description",
				content: (d) => (
					<>
						<span className="fw-bold">{d.stockingName}</span>
						<br />
						{d.description}
					</>
				),
			},
			{
				label: "ab / bis",
				path: "startDate",
				content: (d) => (
					<>
						{Format.date(new Date(d.startDate))}
						<br />
						{d.endDate !== undefined ? Format.date(new Date(d.endDate)) : "-"}
					</>
				),
			},
			{
				label: "Intervall / Betrag",
				path: "enrollmentDate",
				align: "end",
				content: (d) => (
					<>
						{RecurringService.intervalTypes.find((i) => i.value === d.interval.toString()).label}
						<br />
						{Format.money(d.value, currency)}
					</>
				),
			},
		];

		if (table === "active") {
			// add further columns for the active table
			return [
				...columns,
				// {
				// 	label: "",
				// 	path: "stockingId",
				// 	align: "end",
				// 	content: (d) => <Gear className="text-primary clickable" size="24" onClick={() => this.handleEdit(d)} />,
				// },
			];
		}
	};

	state = {
		stockings: [],
		recurrings: [],
		activeColumns: [],
		activeSort: { key: "stockingPosition", order: "asc" },
		pastSort: { key: "stockingPosition", order: "asc" },
	};

	// handleEdit = (stocking) => {
	// 	const { account } = this.props;
	// 	const { accountId } = account;
	// 	const deletable = stocking.balance === 0;
	// 	const archivable = !stocking.hasUnprocessedTransactions;
	// 	const data = { name: stocking.name, type: stocking.type, stockingId: stocking.stockingId, color: stocking.color };
	// 	this.context.showFormModal(
	// 		"Sparstrumpf bearbeiten",
	// 		StockingForm,
	// 		{ accountId, buttonLabel: "Speichern", data, deletable, archivable },
	// 		"md",
	// 		this.handleStockingUpdate
	// 	);
	// };

	// handleStockingUpdate = (operation, stocking) => {
	// 	this.context.hideModal();
	// 	if (operation === "update") {
	// 		if (stocking !== null) {
	// 			const balance = this.state.stockings.find((s) => s.stockingId === stocking.stockingId).balance;
	// 			const hasUnprocessedTransactions = this.state.stockings.find((s) => s.stockingId === stocking.stockingId).hasUnprocessedTransactions;
	// 			const stockings = [...this.state.stockings];
	// 			const idx = stockings.findIndex((s) => s.stockingId === stocking.stockingId);
	// 			stockings[idx] = { ...stocking, balance, hasUnprocessedTransactions };
	// 			this.setState({ stockings });
	// 		}
	// 	} else if (operation === "delete") {
	// 		const stockings = this.state.stockings.filter((s) => s.stockingId !== stocking.stockingId);
	// 		this.setState({ stockings });
	// 	}
	// };

	handleActiveSort = (sort) => {
		this.setState({ activeSort: sort });
	};

	handlePastSort = (sort) => {
		this.setState({ pastSort: sort });
	};

	loadStockings = async () => {
		const { account } = this.props;
		if (account) {
			const stockings = await StockingService.getStockings(account.accountId);
			this.setState(
				{
					stockings,
					activeColumns: this.getColumns("active", account.currency),
					activeNarrowColumns: this.getNarrowTableColumns("active", account.currency),
				},
				this.loadRecurrings
			);
		}
	};

	loadRecurrings = async () => {
		const { account } = this.props;
		this.state.stockings.forEach(async (stocking) => {
			const recurringsOfStocking = await RecurringService.getRecurrings(account.accountId, stocking.stockingId);
			recurringsOfStocking.forEach((r) => {
				r.stockingName = stocking.name;
				r.stockingColor = stocking.color;
				r.stockingPosition = stocking.position;
			});

			this.setState((prevState) => {
				console.log(recurringsOfStocking);
				const recurrings = [...prevState.recurrings, ...recurringsOfStocking];
				return { recurrings };
			});
		});
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.account !== this.props.account) {
			this.loadStockings();
		}
	}

	async componentDidMount() {
		this.loadStockings();
	}

	render() {
		const { recurrings, activeSort, pastSort, activeColumns, activeNarrowColumns } = this.state;
		const activeRecurrings = _.orderBy(
			recurrings.filter((r) => r.endDate === undefined || new Date(r.endDate) > new Date()),
			[activeSort.key],
			[activeSort.order]
		);
		const pastRecurrings = _.orderBy(
			recurrings.filter((r) => r.endDate !== undefined && new Date(r.endDate) <= new Date()),
			[pastSort.key],
			[pastSort.order]
		);

		return (
			<div className="m-3 mb-5">
				<div className="mt-5 text-center">
					<div className="h2 mb-5">Wiederkehrende Buchungen</div>
					{activeRecurrings.length > 0 && (
						<>
							<div className="d-none d-md-block">
								<Table data={activeRecurrings} columns={activeColumns} sort={activeSort} onSort={this.handleActiveSort} />
							</div>
							<div className="d-block d-md-none">
								<Table data={activeRecurrings} columns={activeNarrowColumns} sort={activeSort} onSort={this.handleActiveSort} />
							</div>
						</>
					)}

					{activeRecurrings.length === 0 && <div>Es sind keine wiederkehrenden Buchungen vorhanden.</div>}
				</div>

				{pastRecurrings.length > 0 && (
					<div className="mt-5 text-center">
						<div className="h2 mb-5">Ehemalige wiederkehrende Buchungen</div>
						<div className="d-none d-md-block">
							{<Table data={pastRecurrings} columns={activeColumns} sort={pastSort} onSort={this.handlePastSort} />}
						</div>
						<div className="d-block d-md-none">
							{<Table data={pastRecurrings} columns={activeNarrowColumns} sort={pastSort} onSort={this.handlePastSort} />}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default RecurringManagement;
