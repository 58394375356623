import React, { useContext } from "react";
import RegisterForm from "./forms/registerForm";
import ApplicationContext from "../services/applicationContext";
import { Redirect } from "react-router-dom";

const Register = () => {
	const context = useContext(ApplicationContext);
	const { registrationFreemonths } = context.configuration;

	if (context.user) return <Redirect to="/app/start" />;
	return (
		<React.Fragment>
			<div className="row mx-3">
				<div className="col-md-2 col-lg-3 col-xl-3"></div>
				<div className="col-md-8 col-lg-6 col-xl-6 mb-5">
					<center>
						<h1 className="mt-5">Registrierung</h1>
						<p className="mt-5 lead">
							Danke für Dein Interesse an Sparstrumpf. Die Registrierung ist kostenlos. Du kannst das Angebot während der Beta-Phase und danach{" "}
							{registrationFreemonths} Monate kostenfrei und vollumfänglich nutzen und kennenlernen.
						</p>
						<p className="mt-4 mb-5 small justify">
							Willst Du Sparstrumpf nach dem Testzeitraum weiter nutzen, schliesse bitte eines unserer günstigen Abonnements ab. Anderenfalls
							schalten wir Dein Konto in den "Nur-Lesen"-Modus. Du kannst dann keine Veränderungen mehr vornehmen, behältst aber den Zugriff auf
							Deine Daten. Wir behalten uns das Recht vor, inaktive Konten nach Ablauf einer angemessenen Frist zu löschen.
						</p>
					</center>
					<RegisterForm />
				</div>
				<div className="col-auto"> </div>
			</div>
		</React.Fragment>
	);
};

export default Register;
