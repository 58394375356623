import http from "./httpService";

const supportedCurrencies = [
	{ value: "EUR", label: "Euro (EUR)" },
	{ value: "GBP", label: "Britisches Pfund (GBP)" },
	{ value: "DKK", label: "Dänische Kronen (DKK)" },
	{ value: "NOK", label: "Norwegische Kronen (NOK)" },
	{ value: "PLN", label: "Polnischer Zloty (PLN)" },
	{ value: "SEK", label: "Schwedische Kronen (SEK)" },
	{ value: "CHF", label: "Schweizer Franken (CHF)" },
	{ value: "USD", label: "US-Dollar (USD)" },
];

async function getAccounts() {
	const { data: accounts } = await http.get("accounts");
	return accounts;
}

async function updateAccount(accountId, changes) {
	const { data: account } = await http.patch("accounts/" + accountId, changes);
	return account;
}

async function deleteAccount(accountId, params) {
	const { data: result } = await http.delete("accounts/" + accountId, { params });
	return result;
}

async function createAccount(accountData) {
	const { data: account } = await http.post("accounts", accountData);
	return account;
}

async function initAccount(accountId, initAccountData) {
	return http.post("accounts/" + accountId + "/init", initAccountData);
}

async function getQrCode(accountId, transactionGroupId) {
	const { data: qrcode } = await http.get("accounts/" + accountId + "/qrcodes/" + transactionGroupId);
	return qrcode;
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const accountService = {
	supportedCurrencies,
	getAccounts,
	updateAccount,
	deleteAccount,
	createAccount,
	initAccount,
	getQrCode,
	handleExpectedErrors,
};

export default accountService;
