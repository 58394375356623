import http from "./httpService";

async function login(context, credentials) {
	const response = await http.post("auth/login", credentials);
	const { token, user } = response.data;
	http.setJwt(token);
	sessionStorage.setItem("token", token);
	context.handleLogin(user);
}

async function logout(context) {
	await http.post("auth/logout");
	http.setJwt(null);
	sessionStorage.removeItem("token");
	context.handleLogout();
}

function getJwt() {
	return sessionStorage.getItem("token");
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const authService = {
	login,
	logout,
	getJwt,
	handleExpectedErrors,
};

http.setJwt(getJwt());

export default authService;
