import React from "react";
import { Link } from "react-router-dom";

const thanks = {
	register: {
		headline: "Registrierung erfolgreich",
		message: (
			<React.Fragment>
				<p className="lead">Vielen Dank für Deine Registrierung und dem damit verbundenen Interesse an unserem Produkt. </p>
				<p className="mt-5 justify">
					Wir senden Dir umgehend eine E-Mail mit einem Aktivierungs-Link. Dieser ist 48 Stunden gültig. Bitte aktiviere Dein Konto durch Klicken des
					Links. Anschliessend kannst Du Dich einloggen und Sparstrumpf kennen- und hoffentlich lieben lernen. Falls Du in den nächsten Minuten keine
					E-Mail von uns erhältst, wirf kurz mal einen Blick in Deinen Spam-Ordner oder kontaktiere unseren Support mit Hilfe des{" "}
					<Link to="/kontakt">Kontaktformulars</Link>.
				</p>
			</React.Fragment>
		),
		button: "zur Startseite",
		to: "/",
		footer: <p className="lead">Wir wünschen ganz viel Spaß beim Sparen!</p>,
	},
	verify: {
		headline: "Aktivierung erfolgreich",
		message: (
			<React.Fragment>
				<p className="lead">
					Vielen Dank für die Aktivierung Deines Kontos.
					<br /> Jetzt kanns losgehen.
				</p>
			</React.Fragment>
		),
		button: "zum Login",
		to: "/login",
		footer: (
			<p className="justify fs-6">
				Wir wünschen Dir viel Erfolg und Spaß beim Kennenlernen von Sparstrumpf. Sollten dabei Fragen auftauchen, hilft Dir eventuell unser{" "}
				<Link to="/faq">FAQ-Bereich</Link> weiter. Auf alle verbleibenden Fragen finden wir sicher gemeinsam eine Antwort. Wende Dich einfach per{" "}
				<Link to="/kontakt">Kontaktformular</Link> direkt an unseren Kundendienst.
			</p>
		),
	},
	forgot: {
		headline: "Vielen Dank",
		message: (
			<React.Fragment>
				<p className="lead">
					Wir senden Dir umgehend eine E-Mail mit weiteren Instruktionen zu. Bitte nutze den Link in der E-Mail um für Dein Konto ein neues Passwort
					zu vergeben.
				</p>
			</React.Fragment>
		),
		button: "zum Login",
		to: "/login",
		footer: (
			<p className="justify fs-6">
				Bitte prüfe in einigen Augenblicken Dein Mail-Postfach. Solltest Du keine E-Mail von uns erhalten haben, schaue bitte auch in Deinen
				Spam-Ordner. Falls Du weiterhin Schwierigkeiten hast, kannst Du Dich auch gern mit Hilfe unseres <Link to="/kontakt">Kontaktformulars</Link> an
				unseren Kundendienst wenden.
			</p>
		),
	},
	resetted: {
		headline: "Vielen Dank",
		message: (
			<React.Fragment>
				<p className="lead">Dein Passwort wurde erfolgreich geändert.</p>
			</React.Fragment>
		),
		button: "zum Login",
		to: "/login",
		footer: (
			<p className="justify fs-6">
				Wir wünschen Dir weiterhin viel Spaß beim Verwenden von Sparstrumpf. Sollten dabei Fragen auftauchen, hilft Dir eventuell unser{" "}
				<Link to="/faq">FAQ-Bereich</Link> weiter. Auf alle verbleibenden Fragen finden wir sicher gemeinsam eine Antwort. Wende Dich einfach per{" "}
				<Link to="/kontakt">Kontaktformular</Link> direkt an unseren Kundendienst.
			</p>
		),
	},
};

const ThankYou = (props) => {
	const data = thanks[props.for];
	return (
		<React.Fragment>
			<div className="row mx-3">
				<div className="col-md-2 col-lg-3 col-xl-3"></div>
				<div className="col-md-8 col-lg-6 col-xl-6 mb-5">
					<center>
						<h1 className="mt-5">{data.headline}</h1>
						<div className="my-5">{data.message}</div>
						<Link to={data.to} className="btn btn-primary">
							{data.button}
						</Link>
						<div className="my-5 ">{data.footer}</div>
					</center>
				</div>
				<div className="col-auto"> </div>
			</div>
		</React.Fragment>
	);
};

export default ThankYou;
