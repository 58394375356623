import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const FormModal = (props) => {
	const {
		className,
		headerClassName,
		isOpen,
		title,
		size,
		bodyComponent: BodyComponent,
		footerComponent: FooterComponent,
		properties,
		verticallyCentered,
		onClose,
		onSubmitted,
	} = props;

	const [confirmed, setConfirmed] = useState(false);

	// Modify Footer Properties -> Disable/Enable button according to confirmation status of BodyComponent
	const footerProperties = { ...properties };
	if (properties.buttons) footerProperties["buttons"] = properties.buttons.map((b) => (b.confirmationRequired ? { ...b, disabled: !confirmed } : b));

	return (
		<React.Fragment>
			<Modal isOpen={isOpen} toggle={onClose} onExit={onClose} backdrop="static" size={size} centered={verticallyCentered}>
				<ModalHeader className={headerClassName} toggle={onClose}>
					{title}
				</ModalHeader>
				<ModalBody>
					<div className={className || "m-2"}>
						{BodyComponent && <BodyComponent onSubmitted={onSubmitted} onConfirmation={setConfirmed} {...properties} />}
					</div>
				</ModalBody>
				{FooterComponent && (
					<ModalFooter>
						<FooterComponent onSubmitted={onSubmitted} {...footerProperties} />
					</ModalFooter>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default FormModal;
