import React, { Component } from "react";
import ListCard from "../common/listCard";
import Format from "../../services/formattingService";
import Validation from "../../services/validation";

class AccountDetailsCard extends Component {
	render() {
		const { account } = this.props;
		const items = [
			{
				label: "Name",
				value: account ? account.name : null,
				editable: true,
				name: "name",
				validationSchema: Validation.getFieldSchema("accountFormSchema", "name"),
			},
			{
				label: "Währung",
				value: account ? account.currency : null,
				editable: true,
				name: "currency",
				validationSchema: Validation.getFieldSchema("accountFormSchema", "currency"),
			},
			{
				label: "IBAN",
				value: account && account.iban ? Format.iban(account.iban) : "-",
				editable: true,
				name: "iban",
				validationSchema: Validation.getFieldSchema("accountFormSchema", "iban"),
			},
			{
				label: "Konto-Inhaber",
				value: account ? account.owner || "-" : null,
				editable: true,
				name: "owner",
				validationSchema: Validation.getFieldSchema("accountFormSchema", "owner"),
			},
			{ label: "Überweisungstext QR-Code", value: account?.transferDescription || "-", editable: true, name: "transferDescription" },
			{ label: "Hauptkonto", value: account ? (account.main ? "ja" : "nein") : null },
		];
		// const link = {
		// 	label: "Anlegen",
		// 	onClick: this.props.handleAccountCreate,
		// };

		const handleEdit = (data) => {
			const { handleUpdate } = this.props;
			if (data.name === "iban") data.value = data.value.trim().replace(/\s/g, "");
			else data.value = data.value.trim();

			if (account[data.name] !== data.value) {
				handleUpdate(account, data);
			}
		};

		return <> {account && <ListCard title={account.name} items={items} onEdit={handleEdit} />} </>;
	}
}

export default AccountDetailsCard;
