import BaseJoi from "joi";
import JoiIbanExtension from "./ibanValidation";
import { getMonth } from "./dateService";
import Format from "./formattingService";
import Account from "./accountService";

const Joi = BaseJoi.extend(JoiIbanExtension);

// Schemas
const schemas = {};

// Options
const options = { abortEarly: false, convert: false };
const optionsP = { convert: false };
const emailOptions = { tlds: { allow: false } };

// Error Messages
const accountNameValidationMessages = { "string.empty": "Bitte vergib einen Namen für dieses Konto." };
const balanceValidationMessages = { "number.base": "Bitte gib eine gültige Zahl ein.", "number.precision": "Bitte gib nur 2 Nachkommastellen an." };
const currencyNameValidationMessages = {
	"string.empty": "Bitte wähle eine Währung für dieses Konto.",
	"any.only": "Bitte wähle einer folgenden Währungen: " + Account.supportedCurrencies.map((c) => c.value).join(", "),
};
const descriptionValidationMessages = { "string.empty": "Bitte gib eine Beschreibung ein." };
const emailValidationMessages = {
	"string.empty": "Bitte gib Deine E-Mail-Adresse an.",
	"string.email": "Bitte gib eine gültige E-Mail-Adresse ein.",
};
const emailValidationMessagesForContact = {
	"string.empty": "Bitte gib eine E-Mail-Adresse an, damit wir Dir antworten können.",
	"string.email": "Bitte gib eine gültige E-Mail-Adresse ein.",
};
const ibanValidationMessages = { "string.empty": "", "string.invalidIBAN": "Bitte gib eine gültige IBAN ein." };
const messageValidationMessages = { "string.empty": "Bitte gib eine Nachricht ein." };
const nameValidationMessages = { "string.empty": "Bitte gib Deinen Namen an." };
const ownerValidationMessages = { "string.pattern.base": "Bitte gib einen vollständigen Namen ein." };
const passwordValidationMessages = {
	"string.empty": "Bitte gib Dein Passwort an.",
};
const passwordValidationMessagesForRegister = {
	"string.empty": "Bitte vergib ein Passwort für Dein Konto.",
	"string.min": "Das Passwort muss aus mindestens 8 Zeichen bestehen.",
	"string.max": "Das Passwort darf maximal 50 Zeichen haben.",
	"string.pattern.base": "Das Passwort muss aus Zahlen, Groß- und Kleinbuchstaben bestehen",
};
const passwordConfirmationMessages = { "any.only": "Die Eingaben sind nicht identisch.", "string.empty": "Bitte gib Dein Passwort erneut ein." };
const startDateValidationMessages = { "date.min": "Das Datum darf nicht in Vormonaten liegen" };
const endDateValidationMessages = { "date.min": "Das Datum darf nicht in Vormonaten liegen" };
const repititionsValidationMessages = {
	"number.min": "Es muss mindestens eine Wiederholung sein.",
	"number.base": "Geben Sie bitte die Anzahl der Wiederholungen ein",
};
const stockingNameValidationMessages = { "string.empty": "Bitte vergib einen Namen für diesen Strumpf." };
const stockingTypeValidationMessages = { "string.empty": "Bitte lege einen Typ für diesen Strumpf fest." };
const valueValidationMessages = {
	"number.base": "Bitte gib einen gültigen Betrag ein.",
	"any.invalid": "Bitte gib einen Betrag ungleich 0 ein.",
	"number.precision": "Bitte gib nur zwei Nachkommastellen an.",
};
const dateRangeValidationMessages = { "date.base": "Ungültiges Datum" };

function init(config) {
	const { passwordMax, passwordMin, passwordPattern } = config;

	// create contact form schema
	schemas.contactFormSchema = Joi.object({
		userId: Joi.any(),
		name: Joi.string().required().messages(nameValidationMessages),
		email: Joi.string().email(emailOptions).required().messages(emailValidationMessagesForContact),
		message: Joi.string().required().messages(messageValidationMessages),
	});

	// create register form schema
	const registerForm = {
		name: Joi.string().required().messages(nameValidationMessages),
		email: Joi.string().email(emailOptions).required().messages(emailValidationMessages),
		password: Joi.string()
			.required()
			.min(parseInt(passwordMin || 1))
			.max(parseInt(passwordMax || 500))
			.regex(RegExp(passwordPattern || ".*"))
			.messages(passwordValidationMessagesForRegister),
		passwordConfirmation: Joi.string().equal(Joi.ref("password")).messages(passwordConfirmationMessages),
	};
	schemas.registerFormSchema = Joi.object(registerForm);
	schemas.registerFormPropertySchema = Joi.object({ ...registerForm, passwordConfirmation: Joi.string().messages(passwordConfirmationMessages) });

	// create login form schema
	schemas.loginFormSchema = Joi.object({
		username: Joi.string().required().messages(emailValidationMessages),
		password: Joi.string().required().messages(passwordValidationMessages),
	});

	// password forgotten form schema
	schemas.passwordForgottenFormSchema = Joi.object({
		username: Joi.string().email(emailOptions).required().messages(emailValidationMessages),
	});

	// password reset form schema
	const resetPasswordForm = {
		username: Joi.string(), // this is grabbed via the token
		token: Joi.string(),
		password: Joi.string()
			.required()
			.min(parseInt(passwordMin || 1))
			.max(parseInt(passwordMax || 500))
			.regex(RegExp(passwordPattern || ".*"))
			.messages(passwordValidationMessagesForRegister),
		passwordConfirmation: Joi.string().equal(Joi.ref("password")).messages(passwordConfirmationMessages),
	};

	schemas.resetPasswordFormSchema = Joi.object(resetPasswordForm);
	schemas.resetPasswordFormPropertySchema = Joi.object({ ...resetPasswordForm, passwordConfirmation: Joi.string().messages(passwordConfirmationMessages) });

	// create account form schema
	schemas.accountFormSchema = Joi.object({
		name: Joi.string().required().messages(accountNameValidationMessages),
		currency: Joi.string()
			.required()
			.valid(...Account.supportedCurrencies.map((c) => c.value))
			.messages(currencyNameValidationMessages),
		iban: Joi.string().allow("").iban().messages(ibanValidationMessages),
		owner: Joi.string().allow("").regex(RegExp("^.+\\s+.+$")).messages(ownerValidationMessages),
		balance: Joi.number().required().messages(balanceValidationMessages),
	});

	// open balance form schema
	schemas.openBalanceFormSchema = Joi.object({
		openBalance: Joi.number().valid(0),
		stockings: Joi.array(),
	});

	// balance distribution sub-form schema
	schemas.balanceDistributionSubformSchema = Joi.object({
		name: Joi.string().required().messages(stockingNameValidationMessages),
		type: Joi.string().required().messages(stockingTypeValidationMessages),
		editing: Joi.boolean(),
		balance: Joi.number().precision(2).messages(balanceValidationMessages),
	});

	// transaction form schema
	schemas.transactionFormSchema = Joi.object({
		type: Joi.string().valid("deposit", "payout").required(),
		includeInCMD: Joi.boolean().required(),
		bookingDate: Joi.date().required(),
		groupId: Joi.string().allow(""),
		description: Joi.string().required().messages(descriptionValidationMessages),
		value: Joi.number().precision(2).invalid(0).messages(valueValidationMessages),
	});

	// transaction form schema
	schemas.recurringFormSchema = Joi.object({
		type: Joi.string().valid("deposit", "payout").required(),
		includeInCMD: Joi.boolean().required(),
		startDate: Joi.date()
			.min(Format.isodate(getMonth(new Date())))
			.required()
			.messages(startDateValidationMessages),
		endDate: Joi.any().messages(endDateValidationMessages),
		repititions: Joi.number().precision(0).min(1).required().messages(repititionsValidationMessages),
		setEndDate: Joi.boolean().required(),
		setRepititions: Joi.boolean().required(),
		interval: Joi.string().allow("1", "2", "3", "6", "9", "12", "24", "36").required(),
		description: Joi.string().required().messages(descriptionValidationMessages),
		value: Joi.number().precision(2).invalid(0).messages(valueValidationMessages),
	});

	schemas.stockingFormSchema = Joi.object({
		name: Joi.string().required().messages(stockingNameValidationMessages),
		type: Joi.string().required().messages(stockingTypeValidationMessages),
		stockingId: Joi.any(),
		color: Joi.any(),
	});

	schemas.dateRangeFormSchema = Joi.object({
		from: Joi.date().required().messages(dateRangeValidationMessages),
		to: Joi.date().required().messages(dateRangeValidationMessages),
	});

	schemas.descriptionFieldSchema = Joi.object({
		editorValue: Joi.string().required().messages(descriptionValidationMessages),
	});

	schemas.valueFieldSchema = Joi.object({
		editorValue: Joi.number().precision(2).invalid(0).messages(valueValidationMessages),
	});
}

// Extracts a single field validation object from a bigger form validation object
function getFieldSchema(schemaName, fieldName) {
	return Joi.object({
		editorValue: schemas[schemaName].extract(fieldName),
	});
}

// Validation Functions
function validate(valueObject, schemaObject) {
	if (schemaObject) {
		const { error } = schemaObject.validate(valueObject, options);
		//console.log("joiResult", error);
		const errors = {};
		if (error) {
			error.details.forEach((detail) => {
				const rule = schemaObject.extract(detail.path);
				if (rule._preferences && rule._preferences.messages && !rule._preferences.messages[detail.type]) {
					errors[detail.path] = detail.type;
				} else {
					errors[detail.path] = detail.message;
				}
			});
			return errors;
		}
	}
	return null;
}

function validateProperty(name, value, schemaObject) {
	if (schemaObject) {
		const propertySchema = schemaObject.extract(name);
		const { error } = propertySchema.validate(value, optionsP);
		//console.log("joiResult", error);
		if (error) {
			const detail = error.details[0];
			if (propertySchema._preferences && propertySchema._preferences.messages && !propertySchema._preferences.messages[detail.type]) {
				return detail.type;
			} else {
				return detail.message;
			}
		}
	}
	return null;
}

const exports = {
	init,
	validate,
	validateProperty,
	schemas,
	getFieldSchema,
};

export default exports;
