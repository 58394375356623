import React, { useContext } from "react";
import { toast } from "react-toastify";
import ApplicationContext from "../../services/applicationContext";
import AccountDetailsCard from "./accountDetailsCard";
import AddButton from "../common/addButton";
import Account from "../../services/accountService";
import ResponsiveButton from "../common/forms/responsiveButton";

const Accounts = (props) => {
	const context = useContext(ApplicationContext);
	const { getLicenseType, showAccountForm } = context;
	const { accounts, onAccountUpdate, onAccountDelete } = props;

	const deleteAccount = async (accountId, token) => {
		try {
			const params = token ? { deletionToken: token } : {};
			await Account.deleteAccount(accountId, params);
		} catch (error) {
			if (error.response.status === 417) {
				const stockingCount = error.response.data.elementCount;
				const deletionToken = error.response.data.deletionToken;
				showConfirmationDialog(accountId, stockingCount, deletionToken);
			} else {
				toast.warn("Deine Änderung konnte nicht übernommen werden.");
			}
			return false;
		}
		onAccountDelete(accountId);
		toast.success("Das Basiskonto wurde erfolgreich gelöscht.");
		return true;
	};

	const handleAccountUpdate = async (account, data) => {
		const accountUpdate = {};
		accountUpdate[data.name] = data.value;

		const previousAccount = { ...account };
		const newAccount = { ...account, ...accountUpdate };
		onAccountUpdate(newAccount);

		try {
			await Account.updateAccount(account.accountId, accountUpdate);
		} catch (error) {
			console.log("error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			onAccountUpdate(previousAccount);
		}
	};

	const switchAccountAsMain = async (account) => {
		const { accounts, onAccountUpdate } = props;

		const previousAccounts = accounts.map((acc) => ({ ...acc }));
		const newAccounts = accounts.map((acc) => ({ ...acc, main: acc.accountId === account.accountId }));
		newAccounts.forEach((a) => onAccountUpdate(a));

		try {
			const accountUpdate = { main: true };
			await Account.updateAccount(account.accountId, accountUpdate);
		} catch (error) {
			console.log("error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			previousAccounts.forEach((a) => onAccountUpdate(a));
		}
	};

	const handleAdd = () => {
		showAccountForm();
	};

	const handleConfirmationDialogResult = (value) => {
		const { result, accountId, deletionToken } = value;
		context.hideDialogModal();
		if (result === "delete") {
			deleteAccount(accountId, deletionToken);
		}
	};

	const showConfirmationDialog = (accountId, stockingCount, deletionToken) => {
		const confirmationKeyword = "LÖSCHEN";
		const confirmationText =
			"Dieses Basiskonto enthält noch " +
			stockingCount +
			" " +
			(stockingCount === 1 ? "Sparstrumpf" : "Sparstrümpfe") +
			". Soll es wirklich gelöscht werden? Es werden alle Sparstrümpfe mit sämtlichen Transkationen unwiderruflich gelöscht. Diese Aktion kann nicht rückgängig gemacht werden!";
		const confirmationButtons = [
			{
				label: "Unwiderruflich löschen",
				value: { result: "delete", accountId, deletionToken },
				confirmationRequired: true,
				disabled: true,
				disabledHint: "Bitte gib zunächst das Wort zur Bestätigung ein",
				color: "danger",
				hint: "Löscht diesen Sparstrumpf unwiderruflich.",
			},
			{ label: "Abbrechen", value: { result: "cancel", accountId }, color: "secondary", hint: "Bricht das Löschen ab." },
		];
		context.showDialogModal(
			"Warnung",
			confirmationText,
			confirmationButtons,
			"md",
			handleConfirmationDialogResult,
			confirmationKeyword,
			null,
			"bg-warning"
		);
	};

	const handleDialogResult = async (value) => {
		const { result, accountId } = value;

		if (result === "delete") {
			const success = await deleteAccount(accountId);
			if (success) context.hideDialogModal();
		} else {
			context.hideDialogModal();
		}
	};

	const handleFirstDeleteClick = (event, accountId) => {
		event.preventDefault();
		const confirmationText = "Soll dieses Basiskonto wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden!";
		const confirmationButtons = [
			{ label: "Unwiderruflich löschen", value: { result: "delete", accountId }, color: "danger", hint: "Löscht diesen Sparstrumpf unwiderruflich." },
			{ label: "Abbrechen", value: { result: "cancel", accountId }, color: "secondary", hint: "Bricht das Löschen ab." },
		];
		context.showDialogModal("Achtung", confirmationText, confirmationButtons, "md", handleDialogResult);
	};

	const accountsAndAddButton = accounts ? [...accounts] : [];
	if (getLicenseType() === "pro" || accounts?.length === 0) accountsAndAddButton.push({ addButton: true });

	const hasLicense = context.getLicenseType();

	return (
		<React.Fragment>
			<div className="text-center">
				<h1 className="mt-5">{getLicenseType() === "pro" ? "Basiskonten" : "Basiskonto"}</h1>
			</div>
			<p className="mt-5 px-5 px-md-2 px-lg-5 mx-xl-5 justify">
				Hier findest Du Details über Dein Basiskonto (Pro-User können mehrere davon haben). Du kannste Deine Angaben anpassen oder das gesamte Konto
				(mit allen Sparstrümpfen und Buchungen!) löschen.
			</p>

			<div className="row pb-5 pb-xl-4 px-4 px-sm-0">
				<div className="d-none d-sm-block d-md-none d-lg-block col-1 mt-5"></div>
				<div className="col-12 col-sm-10 col-md-12 col-lg-10">
					<div className="row">
						{accountsAndAddButton.map((account, idx) => (
							<div key={idx} className="col-md-6 col-xxl-6 mt-5 px-xl-5 pb-xl-3">
								{account.accountId && (
									<>
										<AccountDetailsCard account={account} handleUpdate={handleAccountUpdate} />
										<div className="d-flex mt-2 me-3 justify-content-end">
											{!account.main && (
												<ResponsiveButton
													label="Hauptkonto"
													color="primary"
													hint="Macht dieses Konto zum Hauptkonto, welches beim Einloggen direkt geöffnet wird und beim Rückstufen der Lizenz auf Basis weiterverwendet werden kann."
													hasLicense={hasLicense}
													disabled={false}
													className="me-2"
													onClick={() => switchAccountAsMain(account)}
												/>
											)}

											<ResponsiveButton
												label="Löschen"
												color="danger"
												hint="Löscht dieses Basiskonto mit allen Sparstrümpfen und deren Buchungen."
												hasLicense={hasLicense}
												disabled={false}
												className=""
												onClick={(e) => handleFirstDeleteClick(e, account.accountId)}
											/>
										</div>
									</>
								)}
								{account.addButton && (
									<div className="ms-3">
										<AddButton onClick={handleAdd} title="Neues Basiskonto" />
									</div>
								)}
							</div>
						))}
					</div>
				</div>

				<div className="col-lg-1"></div>
			</div>
		</React.Fragment>
	);
};

export default Accounts;
