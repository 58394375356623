import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { isFuture, getMonth } from "../../../services/dateService";
import TransactionService from "../../../services/transactionService";
import RecurringService from "../../../services/recurringService";
import Format from "../../../services/formattingService";
import MonthPlate from "./monthPlate";

class StockingBody extends PureComponent {
	noTransactions = [];

	groupTransactionsByMonth = (transactionList) => {
		const transactions = {};
		if (transactionList) {
			transactionList.forEach((t) => {
				const tInMonth = transactions[getMonth(t.bookingDate)] || [];
				tInMonth.push(t);
				transactions[getMonth(t.bookingDate)] = tInMonth;
			});
		}
		return transactions;
	};

	calculateCurrentValue = () => {
		const { startValue, transactions } = this.state;
		let s = startValue;
		if (transactions) {
			transactions.filter((trx) => trx.processed).forEach((trx) => (s += trx.value));
		}
		return s;
	};

	getUnprocessedTransactions = () => {
		const { transactions } = this.state;
		if (transactions) return transactions.filter((t) => !t.processed && !isFuture(getMonth(t.bookingDate)));
		else return [];
	};

	calculateMonthlyBalances = () => {
		const { startValue, transactionsByMonth } = this.state;
		const { months } = this.props;
		const monthlyBalances = {};

		let currentBalance = startValue;
		months.forEach((month) => {
			if (transactionsByMonth[month])
				transactionsByMonth[month].forEach((trx) => (currentBalance = Math.round((currentBalance + trx.value) * 100) / 100));
			monthlyBalances[month] = currentBalance;
		});

		return monthlyBalances;
	};

	handleCreate = (transactionType, month) => {
		const { stockingId, onCreate } = this.props;
		onCreate(this.handleCreated, stockingId, transactionType, month);
	};

	handleCreated = (transactionOrRule) => {
		// es kann beides kommen, weil man im Formular umschalten kann
		if (transactionOrRule.bookingDate) {
			// we have a transaction here
			this.replaceTransactionsInState(null, [transactionOrRule]);
		}
		this.loadTransactions();
	};

	handleTransactionMoveBackward = (transactionId) => {
		this.shiftTransaction(transactionId, -1);
	};

	handleTransactionMoveForward = (transactionId) => {
		this.shiftTransaction(transactionId, 1);
	};

	getShiftedBookingDate = (bookingDate, byMonths, recursionCounter = 0) => {
		const newBookingDate = new Date(bookingDate.getTime());
		newBookingDate.setMonth(newBookingDate.getMonth() + byMonths);

		if (newBookingDate.getDate() !== bookingDate.getDate() && recursionCounter < 10) {
			const betterTry = new Date(bookingDate.getTime() - 86400000);
			return this.getShiftedBookingDate(betterTry, byMonths, recursionCounter + 1);
		}
		return newBookingDate;
	};

	shiftTransaction = async (transactionId, byMonths) => {
		const { account, stockingId } = this.props;
		const { transactions } = this.state;

		const transaction = transactions.filter((t) => t.id === transactionId)[0];
		const newTransaction = { ...transaction };
		newTransaction.bookingDate = this.getShiftedBookingDate(transaction.bookingDate, byMonths);
		this.replaceTransactionsInState([transaction], [newTransaction]);

		try {
			const changes = { bookingDate: Format.isodate(newTransaction.bookingDate) }; // format yyyy-mm-dd
			await TransactionService.updateTransaction(account.accountId, stockingId, transaction.id, changes);
			await this.loadTransactions();
		} catch (error) {
			console.log("error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			this.replaceTransactionsInState([newTransaction], [transaction]);
		}
	};

	handleTransactionDelete = (transactionId, editRecurring) => {
		if (editRecurring) {
			this.adjustRecurringEndDate(transactionId);
		} else {
			this.removeTransaction(transactionId);
		}
	};

	removeTransaction = async (transactionId) => {
		const { account, stockingId } = this.props;
		const transaction = this.state.transactions.filter((t) => t.id === transactionId)[0];
		this.replaceTransactionsInState([transaction], null);
		try {
			await TransactionService.deleteTransaction(account.accountId, stockingId, transaction.id);
			await this.loadTransactions();
		} catch (error) {
			console.log("removeTransaction error", error);
			toast.warn("Deine Buchung konnte nicht gelöscht werden.");
			this.replaceTransactionsInState(null, [transaction]);
		}
	};

	adjustRecurringEndDate = async (transactionId) => {
		const { account, stockingId } = this.props;
		const transaction = this.state.transactions.filter((t) => t.id === transactionId)[0];
		//console.log("Updating recurring's endDate", transaction);
		const affectedTransactions = this.state.transactions.filter(
			(t) => t.recurringId === transaction.recurringId && t.bookingDate >= transaction.bookingDate
		);
		//console.log("Transactions to remove", affectedTransactions);
		this.replaceTransactionsInState(affectedTransactions, null);
		try {
			const changes = { endDate: Format.isodate(transaction.bookingDate) };
			await RecurringService.updateRecurring(account.accountId, stockingId, transaction.recurringId, changes);
			await this.loadTransactions();
		} catch (error) {
			console.log("adjustRecurringEndDate error", error);
			toast.warn("Deine Buchungen konnte nicht gelöscht werden.");
			this.replaceTransactionsInState(null, affectedTransactions);
		}
	};

	handleTransactionProcessed = (transactionId) => {
		this.updateTransaction(transactionId, "processed", true);
	};

	handleTransactionNotProcessed = (transactionId) => {
		this.updateTransaction(transactionId, "processed", false);
	};

	handleTransactionChangeDescription = (transactionId, description, editRecurring) => {
		if (editRecurring) this.updateRecurring(transactionId, "description", description);
		else this.updateTransaction(transactionId, "description", description);
	};

	handleTransactionChangeValue = (transactionId, value, editRecurring) => {
		if (editRecurring) this.updateRecurring(transactionId, "value", Math.round(value * 100) / 100);
		else this.updateTransaction(transactionId, "value", Math.round(value * 100) / 100);
	};

	updateTransaction = async (transactionId, field, value) => {
		const { account, stockingId } = this.props;
		const { transactions } = this.state;

		const transaction = transactions.find((t) => t.id === transactionId);
		const newTransaction = { ...transaction };
		newTransaction[field] = value;
		this.replaceTransactionsInState([transaction], [newTransaction]);

		try {
			const changes = {};
			changes[field] = value;
			await TransactionService.updateTransaction(account.accountId, stockingId, transaction.id, changes);
			await this.loadTransactions();
		} catch (error) {
			console.log("updateTransaction error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			this.replaceTransactionsInState([newTransaction], [transaction]);
		}
	};

	updateRecurring = async (transactionId, field, value) => {
		const { account, stockingId } = this.props;
		const { transactions } = this.state;

		const transaction = transactions.find((t) => t.id === transactionId);
		const newTransaction = { ...transaction };
		newTransaction[field] = value;
		this.replaceTransactionsInState([transaction], [newTransaction]);

		// TODO evtl. hier doch ALLE zukünftigen Transaktionen updaten und dann erst den Request absenden

		try {
			const changes = {};
			changes[field] = value;
			await RecurringService.updateRecurring(account.accountId, stockingId, transaction.recurringId, changes, transaction.bookingDate);
			await this.loadTransactions();
		} catch (error) {
			console.log("updateRecurring error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			this.replaceTransactionsInState([newTransaction], [transaction]);
		}
	};

	replaceTransactionsInState = (previousTransactions, newTransactions) => {
		let transactions;
		if (previousTransactions === null) {
			transactions = [...this.state.transactions, ...newTransactions];
		} else if (newTransactions === null) {
			const previousTransactionIds = previousTransactions.map((t) => t.id);
			transactions = this.state.transactions.filter((t) => !previousTransactionIds.includes(t.id));
		} else {
			if (previousTransactions.length === newTransactions.length) {
				transactions = [...this.state.transactions];
				previousTransactions.forEach((pt, ix) => {
					const transactionIndex = transactions.findIndex((t) => t.id === pt.id);
					transactions[transactionIndex] = newTransactions[ix];
					// untested code
				});
			} else {
				throw new Error("Input array sizes do not match.");
			}
		}

		this.setState({ transactions, transactionsByMonth: this.groupTransactionsByMonth(transactions) }, this.updateApplication);
	};

	updateApplication = () => {
		const { stockingId, onValueUpdate, onUnprocessedTransactions } = this.props;
		onValueUpdate(stockingId, this.calculateCurrentValue());
		onUnprocessedTransactions(stockingId, this.getUnprocessedTransactions(), this.handleTransactionProcessed, this.handleTransactionDelete);
	};

	loadTransactions = async () => {
		const { account, stockingId, months } = this.props;

		if (stockingId !== null) {
			const firstMonth = months[0];
			const lastMonth = new Date(months[months.length - 1].getTime());
			lastMonth.setMonth(lastMonth.getMonth() + 1);

			const from = Format.isodate(firstMonth);
			const to = Format.isodate(lastMonth);
			const data = await TransactionService.getTransactions(account.accountId, stockingId, from, to);
			const transactionsByMonth = this.groupTransactionsByMonth(data.transactions);
			const startValue = data.startValue;

			this.setState({ transactionsByMonth, transactions: data.transactions, startValue }, this.updateApplication);
		}
	};

	state = {
		startValue: 0,
		transactions: [],
		transactionsByMonth: {},
	};

	componentDidMount() {
		const { stockingId, setReloadHook } = this.props;
		setReloadHook(stockingId, this.loadTransactions);
		this.loadTransactions();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.months !== this.props.months) {
			this.loadTransactions();
		}
	}

	render() {
		const { currency, display, months, hidden } = this.props;
		const monthlyBalances = this.calculateMonthlyBalances();

		if (hidden) return null;

		return (
			<div className="stocking mb-4 shadow-sm border-top border-secondary d-flex">
				{months.map((month) => {
					return (
						<MonthPlate
							display={display}
							key={month}
							month={month}
							transactions={this.state.transactionsByMonth[month] || this.noTransactions}
							currency={currency}
							balance={monthlyBalances[month]}
							onTransactionProcessed={this.handleTransactionProcessed}
							onTransactionNotProcessed={this.handleTransactionNotProcessed}
							onTransactionMoveForward={this.handleTransactionMoveForward}
							onTransactionMoveBackward={this.handleTransactionMoveBackward}
							onTransactionDelete={this.handleTransactionDelete}
							onTransactionChangeDescription={this.handleTransactionChangeDescription}
							onTransactionChangeValue={this.handleTransactionChangeValue}
							onCreate={this.handleCreate}
						/>
					);
				})}
			</div>
		);
	}
}

export default StockingBody;
