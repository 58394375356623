import React from "react";
import Input from "../common/forms/input";
import Select from "../common/forms/select";
import Form from "../common/forms/form";
import CheckBox from "../common/forms/checkbox";
import Validation from "../../services/validation";
import { isSameMonth } from "../../services/dateService";
import Format from "../../services/formattingService";
import RecurringService from "../../services/recurringService";
import ApplicationContext from "../../services/applicationContext";
import ResponsiveButton from "../common/forms/responsiveButton";

class RecurringForm extends Form {
	static contextType = ApplicationContext;

	transactionTypes = [
		{ value: "deposit", label: "Einzahlung" },
		{ value: "payout", label: "Auszahlung" },
	];

	getInitialBookingDate = () => {
		return !this.props.month || isSameMonth(this.props.month, new Date()) ? new Date() : this.props.month;
	};

	getInitialEndDate = () => {
		const endDate = new Date(this.getInitialBookingDate());
		endDate.setFullYear(endDate.getFullYear() + 1);
		return endDate;
	};

	toggleEndMode = () => {
		const data = { ...this.state.data };
		data.setEndDate = !data.setEndDate;
		data.setRepititions = !data.setRepititions;
		this.setState({ data });
	};

	state = {
		// @Override
		data: {
			type: this.props.transactionType || "",
			startDate: this.getInitialBookingDate(),
			setEndDate: false,
			setRepititions: false,
			endDate: this.getInitialEndDate(),
			repititions: 1,
			interval: "12",
			description: "",
			includeInCMD: false,
			value: 0,
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.recurringFormSchema;

	// @Override
	doSubmit = async () => {
		const { data } = this.state;
		const { type, startDate, setEndDate, setRepititions, endDate, repititions, interval, description, includeInCMD, value } = data;
		const { stockingId, accountId, onSubmitted } = this.props;

		const roundedValue = Math.round(value * 100) / 100;

		const requestData = {
			type,
			description,
			includeInCMD,
			interval: Number(interval),
			startDate: Format.isodate(startDate),
			value: data.type === "payout" ? -1 * roundedValue : roundedValue,
		};

		if (setEndDate || setRepititions) {
			if (setEndDate && !isNaN(data.endDate.getTime())) {
				requestData.endDate = Format.isodate(endDate);
			} else if (setRepititions) {
				const calculatedEndDate = new Date(startDate);
				calculatedEndDate.addMonths(interval * repititions);
				requestData.endDate = Format.isodate(calculatedEndDate);
			}
		}

		try {
			const recurring = await RecurringService.createRecurring(accountId, stockingId, requestData);
			onSubmitted(recurring);
		} catch (ex) {
			console.log(ex);
			RecurringService.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		const hasLicense = this.context.getLicenseType();

		return (
			<form onSubmit={this.handleSubmit}>
				<Select label="Typ" name="type" error={errors.type} value={data.type} options={this.transactionTypes} onChange={this.handleChange} />

				<CheckBox
					label="Der automatischen Einzahlung zuweisen"
					name="includeInCMD"
					value={data.type === "deposit" && data.includeInCMD}
					error={errors.includeInCMD}
					disabled={data.type === "payout"}
					onChange={this.handleChange}
				/>

				<div className="row">
					<div className="col-6">
						<Input
							label="Start-Datum"
							name="startDate"
							error={errors.startDate}
							type="date"
							value={Format.isodate(data.startDate)}
							onChange={this.handleChange}
						/>
					</div>
					<div className="col-6">
						{(data.setEndDate || data.setRepititions) && (
							<>
								<div className="d-flex justify-content-between align-items-end mb-2">
									<div>{data.setEndDate ? "End-Datum" : "Anzahl"}</div>
									<div
										className="small me-1 text-primary text-decoration-underline"
										style={{ marginBottom: "1px", cursor: "pointer" }}
										onClick={this.toggleEndMode}
									>
										<span className="d-none d-sm-inline">&#8702; </span>
										{data.setEndDate ? "Anzahl" : "Datum"}
									</div>
								</div>
								{data.setEndDate && (
									<Input
										ref={this.endDateRef}
										// label="End-Datum" // removed due to create the link to toggle end-modes
										name="endDate"
										error={errors.endDate}
										type="date"
										value={Format.isodate(data.endDate)}
										onChange={this.handleChange}
									/>
								)}
								{data.setRepititions && (
									<Input
										ref={this.endDateRef}
										// label="Anzahl" // removed due to create the link to toggle end-modes
										name="repititions"
										error={errors.repititions}
										type="number"
										step="1"
										value={data.repititions}
										onChange={this.handleChange}
									/>
								)}
							</>
						)}
						{!data.setEndDate && !data.setRepititions && (
							<CheckBox label="Ende setzen" name="setEndDate" value={data.setEndDate} error={errors.setEndDate} onChange={this.handleChange} />
						)}
					</div>
				</div>

				<Select
					label="Intervall"
					name="interval"
					error={errors.interval}
					value={data.interval}
					options={RecurringService.intervalTypes}
					onChange={this.handleChange}
				/>

				<Input
					label="Beschreibung"
					placeholder={data.type === "deposit" ? "z.B. Sparplan" : "z.B. Jahresbeitrag"}
					name="description"
					error={errors.description}
					type="text"
					value={data.description}
					onChange={this.handleChange}
				/>

				<Input
					label="Betrag"
					placeholder=""
					name="value"
					error={errors.value}
					type="number"
					step="0.01"
					value={data.value}
					onClick={(e) => e.currentTarget.select()}
					onChange={this.handleChange}
				/>

				{errorMessage && (
					<div className="alert alert-danger" role="alert">
						{errorMessage}
					</div>
				)}

				<ResponsiveButton
					label="Anlegen"
					color="primary"
					hint="Erstellt diese wiederkehrende Buchung im System."
					hasLicense={hasLicense}
					disabled={this.validate()}
					disabledHint="Bitte fülle zunächst das Formular aus."
				/>
			</form>
		);
	}
}

export default RecurringForm;
