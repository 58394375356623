import React, { useContext } from "react";
import { toast } from "react-toastify";
import ApplicationContext from "../../services/applicationContext";
import LicenseCard from "./licenseCard";
import UserCard from "./userCard";
import Users from "../../services/userService";

const Profil = (props) => {
	const context = useContext(ApplicationContext);
	const { user } = context;
	const { onUserUpdate } = props;

	const handleUserUpdate = async (data) => {
		const userUpdate = {};
		userUpdate[data.name] = data.value;

		const previousUser = { ...user };
		const newUser = { ...user, ...userUpdate };

		onUserUpdate(newUser);
		try {
			await Users.updateCurrentUser(userUpdate);
		} catch (error) {
			console.log("error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			onUserUpdate(previousUser);
		}
	};

	return (
		<React.Fragment>
			<div className="text-center">
				<h1 className="mt-5">Profil-Informationen</h1>
			</div>

			<p className="mt-5 px-5 px-md-2 px-lg-5 mx-xl-5 text-center">Hier findest Du Details über Dein Benutzerprofil und Deine aktuelle Lizenz.</p>

			{user && (
				<div className="row pb-5 mb-5 px-4 px-sm-0">
					<div className="d-none d-sm-block d-md-none d-lg-block col-1 mt-5"></div>
					<div className="col-12 col-sm-10 col-md-12 col-lg-10">
						<div className="row">
							<div className="col-md-6 col-xxl-6 mt-5 px-xl-5 justify">
								<UserCard user={user} handleUpdate={handleUserUpdate} />
							</div>

							<div className="col-md-6 col-xxl-6 mt-5 px-xl-5">
								<LicenseCard
									license={user.license || user.previousLicenses[user.previousLicenses.length - 1]}
									expired={!user.hasOwnProperty("license")}
								/>
							</div>
						</div>
					</div>

					<div className="col-lg-1"></div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Profil;
