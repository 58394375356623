import React from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import Users from "../../services/userService";
import { toast } from "react-toastify";
import ApplicationContext from "../../services/applicationContext";
import errorMessages from "../../conf/errors.json";
import ResponsiveButton from "../common/forms/responsiveButton";

class RegisterFormComponent extends Form {
	static contextType = ApplicationContext;
	state = {
		// @Override
		data: {
			name: "",
			email: "",
			password: "",
			passwordConfirmation: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.registerFormSchema;
	getPropertySchema = () => Validation.schemas.registerFormPropertySchema;

	// @Override
	doSubmit = async () => {
		try {
			await Users.createUser(this.state.data);
			this.props.history.push("/registrieren/danke");
		} catch (ex) {
			if (ex.response && ex.response.status === 409 && ex.response.data.error === "user_already_exists") {
				// Handle existing users with forward to login page
				toast.error(errorMessages["user_already_exists"], {
					onClose: () => this.props.history.push("/login"),
				});
			} else {
				// handle all other errors in-form
				Users.handleExpectedErrors(ex, this);
			}
		}
	};

	getPasswordNotice = () => {
		const { passwordMax, passwordMin, passwordPattern } = this.context.configuration;
		const text1 = " Zeichen, mindestens je eine Zahl, Klein- und Großbuchstabe";
		const text2 = " Zeichen";
		return (passwordMin || "1") + "-" + (passwordMax || "500") + (passwordPattern ? text1 : text2);
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<Input
						label="Name"
						name="name"
						error={errors.name}
						type="text"
						placeholder="Max Mustermann"
						value={data.name}
						onChange={this.handleChange}
					/>
					<Input
						label="E-Mail"
						name="email"
						error={errors.email}
						type="text"
						placeholder="max@mustermail.de"
						value={data.email}
						onChange={this.handleChange}
					/>
					<Input
						label="Passwort"
						name="password"
						notice={this.getPasswordNotice()}
						error={errors.password}
						type="password"
						placeholder=""
						value={data.password}
						onChange={this.handleChange}
					/>
					<Input
						label="Passwort bestätigen"
						name="passwordConfirmation"
						error={errors.passwordConfirmation}
						type="password"
						placeholder=""
						value={data.passwordConfirmation}
						onChange={this.handleChange}
					/>

					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}

					<ResponsiveButton
						label="Registrieren"
						color="primary"
						hint="Sendet Deine Daten zur Erstellung eines persönlichen Accounts an Mein-Sparstrumpf."
						hasLicense={true}
						disabled={this.validate(this.getPropertySchema)}
						disabledHint="Bitte fülle zunächst das Formular aus."
					/>

					<div className="form-text mt-2">
						Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
					</div>
				</form>
			</div>
		);
	}
}

const RegisterForm = withRouter(RegisterFormComponent);

export default RegisterForm;
