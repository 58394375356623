import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StockingBody from "./stockingbody/stockingBody";
import DateHeaderCenter from "./stockingdateheader/dateHeaderCenter";
import DateHeaderLeft from "./stockingdateheader/dateHeaderLeft";
import StockingHead from "./stockinghead/stockingHead";
import { generateMonthArray, monthDiff } from "../../services/dateService";
import TabbedCreateForm from "../forms/tabbedCreateForm";
import ApplicationContext from "../../services/applicationContext";
import AddButton from "../common/addButton";
import StockingForm from "../forms/stockingForm";

class Stockings extends Component {
	static contextType = ApplicationContext;

	stockingReloadHooks = {};

	scrollarea = React.createRef();
	scrollable = React.createRef();
	stockingArea = React.createRef();

	nextScrollReload = 0;
	overFlowStyle = { overflowX: "scroll" };

	values = {};
	unprocessedTransactions = {};

	setStockingReloadHook = (stockingId, func) => {
		this.stockingReloadHooks[stockingId] = func;
	};

	handleValueUpdate = (stockingId, value) => {
		const { onAccountValueUpdate } = this.props;

		this.values[stockingId] = value;
		this.setState({ values: this.values });

		let accountValue = 0;
		Object.keys(this.values).forEach((stockingId) => (accountValue += this.values[stockingId]));
		onAccountValueUpdate(accountValue);
	};

	handleUnprocessedTransactions = (stockingId, transactions, onProcessed, onDelete) => {
		const { onUnprocessedTransactionsUpdate } = this.props;

		Object.keys(this.unprocessedTransactions).forEach((groupId) => {
			delete this.unprocessedTransactions[groupId][stockingId];
			if (Object.keys(this.unprocessedTransactions[groupId]).length === 0) delete this.unprocessedTransactions[groupId];
		});

		transactions.forEach((transaction) => {
			const tGroupKey = transaction.bookingDate.getYear() + transaction.bookingDate.getMonth() + transaction.groupId;
			const transactionGroup = this.unprocessedTransactions[tGroupKey] || {};
			transactionGroup[stockingId] = { ...transaction, onProcessed, onDelete };
			this.unprocessedTransactions[tGroupKey] = transactionGroup;
		});
		onUnprocessedTransactionsUpdate(this.unprocessedTransactions);
	};

	handleScroll = (e) => {
		const { scrollWidth, scrollLeft, clientWidth } = e.target;
		const { monthMinus, monthPlus } = this.state;

		if (scrollLeft <= 0) {
			const now = new Date().getTime();
			if (now > this.nextScrollReload) {
				this.nextScrollReload = now + 1500;
				const months = generateMonthArray(monthMinus - 12, monthPlus);
				this.setState({ months, monthMinus: this.state.monthMinus - 12 });
			}
			this.scrollToElement(12);
		} else if (scrollLeft >= scrollWidth - clientWidth) {
			const now = new Date().getTime();
			if (now > this.nextScrollReload) {
				this.nextScrollReload = now + 1500;
				const months = generateMonthArray(monthMinus, monthPlus + 12);
				this.setState({ months, monthPlus: this.state.monthPlus + 12 });
			}
		}
	};

	scrollToElement = (el) => {
		const div = this.scrollable.current;
		console.log(div.scrollLeft);
		div.scroll(130 * el, 0);
		console.log(div.scrollLeft);
	};

	scrollToCurrentMonth = () => {
		const div = this.scrollable.current;
		const areaX = this.scrollarea.current.clientWidth;
		const divX = div.clientWidth;
		div.scroll((areaX - divX) / 2 - 130, 0);
	};

	handleCreateSingle = (onCreated, stockingId, transactionType, month) => {
		const { account } = this.props;
		this.showTabbedCreateForm(0, onCreated, account.accountId, stockingId, transactionType, month);
	};

	handleCreateRecurring = (stockingId, transactionType) => {
		const { account } = this.props;
		this.showTabbedCreateForm(1, null, account.accountId, stockingId, transactionType);
	};

	handleEdit = (stocking) => {
		const { onUpdate, account } = this.props;
		const { accountId } = account;
		const data = { name: stocking.name, type: stocking.type, stockingId: stocking.stockingId, color: stocking.color };
		this.context.showFormModal("Sparstrumpf bearbeiten", StockingForm, { accountId, buttonLabel: "Speichern", data }, "md", onUpdate);
	};

	handleAdd = () => {
		const { onCreate, account } = this.props;
		const { accountId } = account;
		this.context.showFormModal("Sparstrumpf anlegen", StockingForm, { accountId, buttonLabel: "Anlegen" }, "md", onCreate, "m-0");
	};

	showTabbedCreateForm = (formIndex, onCreated, accountId, stockingId, transactionType, month) => {
		const params = {
			current: formIndex,
			accountId,
			stockingId,
			transactionType,
			month,
		};
		this.context.showFormModal(
			"Buchung anlegen",
			TabbedCreateForm,
			params,
			"md",
			(createdObject) => {
				this.context.hideModal();
				onCreated != null ? onCreated(createdObject) : this.stockingReloadHooks[stockingId]();
			},
			"m-0"
		);
	};

	handleWindowScroll = (e) => {
		const { dateHeaderPosition } = this.state;
		if (this.stockingArea.current) {
			const scrollTop = e.target.scrollingElement.scrollTop;
			const position = this.stockingArea.current && this.stockingArea.current.offsetTop;
			const newDateHeaderPosition = Math.ceil(Math.max(0, scrollTop - position + 40) / 125);
			if (newDateHeaderPosition !== dateHeaderPosition) {
				this.setState({ dateHeaderPosition: newDateHeaderPosition });
			}
		}
	};

	componentDidMount() {
		this.scrollToCurrentMonth();
		window.addEventListener("scroll", this.handleWindowScroll);
	}

	getMinMonth = () => {
		const { oldestUnprocessedTransactionDate } = this.props.account;
		if (!oldestUnprocessedTransactionDate) return -9;

		try {
			// returns the number of months to load data into the past,
			// normally 9 months by default, but when there are older unprocessed transactions load all data to cover them
			// this will avoid issues with wrong monthly saldo
			return Math.min(-9, -1 * monthDiff(new Date(oldestUnprocessedTransactionDate), new Date()));
		} catch (error) {
			return -9;
		}
	};

	state = {
		monthMinus: this.getMinMonth(),
		monthPlus: 12,
		months: generateMonthArray(this.getMinMonth(), 12),
		values: {},
		dateHeaderPosition: 0,
		dragging: false,
	};

	getListStyle = (isDragging) => {
		return {
			marginTop: isDragging ? "30px" : "0px",
		};
	};

	getItemStyle = (snapshot, draggableStyle) => {
		if (!snapshot.isDropAnimating) {
			return {
				userSelect: "none",
				...draggableStyle,
				opacity: snapshot.isDragging ? "50%" : "100%",
			};
		} else {
			return {
				...draggableStyle,
				transitionDuration: `0.001s`,
			};
		}
	};

	handleCapture = () => {
		this.setState({ dragging: true });
	};

	handleDragEnd = (result) => {
		const { onDrop } = this.props;
		this.setState({ dragging: false });
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		onDrop(result.source.index, result.destination.index);
	};

	isHidden = (stocking) => {
		const { filter } = this.props;
		if (!filter) return false;
		return !stocking.name.toLowerCase().includes(filter.toLowerCase());
	};

	render() {
		// {month.toLocaleString("de-DE", { month: "2-digit", year: "numeric" })}
		const { account, stockings, currency, display } = this.props;
		const { months, dateHeaderPosition, dragging } = this.state;

		return (
			<div className="row mx-2 mx-sm-0 mb-5">
				<DragDropContext onBeforeCapture={this.handleCapture} onDragEnd={this.handleDragEnd}>
					<div className="row p-0 mt-5">
						<div ref={this.stockingArea} className="col-4 col-sm-3 col-lg-2 px-0">
							<Droppable droppableId="stockingArea">
								{(provided, snapshot) => (
									<div {...provided.droppableProps} ref={provided.innerRef} style={this.getListStyle(snapshot.isDragging)}>
										{stockings.map((stocking, idx) => (
											<React.Fragment key={stocking.stockingId}>
												{idx === dateHeaderPosition && <DateHeaderLeft />}
												{!this.isHidden(stocking) && (
													<Draggable key={stocking.stockingId} draggableId={stocking.stockingId} index={idx}>
														{(provided, snapshot) => (
															<div
																className="stockingHeadDraggable"
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={this.getItemStyle(snapshot, provided.draggableProps.style)}
															>
																<StockingHead
																	key={stocking.stockingId}
																	currency={currency}
																	stocking={stocking}
																	value={this.state.values[stocking.stockingId]}
																	onCreate={this.handleCreateRecurring}
																	onEdit={this.handleEdit}
																/>
															</div>
														)}
													</Draggable>
												)}
											</React.Fragment>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</div>

						<div
							ref={this.scrollable}
							className={"col-8 col-sm-9 col-lg-10 px-0" + (dragging ? " opacity-50" : "")}
							style={this.overFlowStyle}
							onScroll={this.handleScroll}
						>
							<div ref={this.scrollarea} className="d-inline-block">
								{stockings.map((stocking, idx) => (
									<React.Fragment key={stocking.stockingId}>
										{idx === dateHeaderPosition && <DateHeaderCenter months={months} />}
										<StockingBody
											key={stocking.stockingId}
											account={account}
											stockingId={stocking.stockingId}
											currency={currency}
											months={months}
											display={display}
											hidden={this.isHidden(stocking)}
											onValueUpdate={this.handleValueUpdate}
											onUnprocessedTransactions={this.handleUnprocessedTransactions}
											onCreate={this.handleCreateSingle}
											setReloadHook={this.setStockingReloadHook}
										/>
									</React.Fragment>
								))}
							</div>
						</div>
					</div>
				</DragDropContext>
				<div className="mb-5">
					<AddButton onClick={this.handleAdd} title="Neuer Sparstrumpf" />
				</div>
			</div>
		);
	}
}

export default Stockings;
