import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ApplicationContext from "../services/applicationContext";

const Footer = () => {
	const context = useContext(ApplicationContext);
	const { serverVersion, registrationFreemonths } = context.configuration;
	return (
		<footer className="bg-light text-center text-lg-start">
			<div className="container p-4">
				{/*Grid row*/}
				<div className="row">
					{/*Grid column*/}
					<div className="col-lg-6 col-md-12 mb-4 mb-md-0">
						<h5 className="text-uppercase">Open Beta!</h5>

						<p>
							Diese Website ist noch in der Beta-Phase. Du kannst Dich anmelden und alle vorhandenen Features nutzen und ausgiebig testen. Falls
							Du einen Fehler findest: Schreib uns über das Kontaktformular. Wenn wir den Bug verizifieren, gibt es als Dankeschön statt{" "}
							{registrationFreemonths} ganze {registrationFreemonths * 2} Monate kostenfreie Nutzung nach dem Launch! Wir garantieren zum
							aktuellen Zeitpunkt keine Systemverfügbarkeit!
						</p>
					</div>
					{/*Grid column*/}

					{/*Grid column*/}
					<div className="col-lg-3 col-md-6 mb-4 mb-md-0">
						<h5 className="text-uppercase">Rechtliches</h5>

						<ul className="list-unstyled mb-0">
							<li>
								<Link to="/impressum" className="text-dark">
									Impressum
								</Link>
							</li>
							<li>
								<Link to="/datenschutz" className="text-dark">
									Datenschutz
								</Link>
							</li>
						</ul>
					</div>
					{/*Grid column*/}

					{/*Grid column*/}
					<div className="col-lg-3 col-md-6 mb-4 mb-md-0">
						<h5 className="text-uppercase">Support</h5>

						<ul className="list-unstyled mb-0">
							<li>
								<Link to="/faq" className="text-dark">
									FAQ
								</Link>
							</li>
							<li>
								<Link to="/kontakt" className="text-dark">
									Kontakt
								</Link>
							</li>
						</ul>
					</div>
					{/*Grid column*/}
				</div>
				{/*Grid row*/}
			</div>
			{/* Grid container */}

			{/* Copyright */}
			<div className="d-flex flex-wrap justify-content-center p-3 App-contrast-background">
				<div className="text-nowrap">
					© 2024 Copyright:&nbsp;
					<a className="text-dark" href="https://www.mein-sparstrumpf.de/">
						Mein-Sparstrumpf.de
					</a>
				</div>
				{serverVersion && <div className="small text-norwap text-muted ms-3">| {serverVersion} |</div>}
			</div>
			{/* Copyright */}
		</footer>
	);
};

export default Footer;
