import http from "./httpService";
import Format from "./formattingService";

const intervalTypes = [
	{ value: "1", label: "monatlich" },
	{ value: "2", label: "alle 2 Monate" },
	{ value: "3", label: "quartalsweise" },
	{ value: "6", label: "halbjährlich" },
	{ value: "12", label: "jährlich" },
	{ value: "24", label: "alle 2 Jahre" },
	{ value: "36", label: "alle 3 Jahre" },
	{ value: "48", label: "alle 4 Jahre" },
];

async function getRecurrings(accountId, stockingId) {
	const { data: recurrings } = await http.get("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings");
	return recurrings;
}

async function createRecurring(accountId, stockingId, recurringRequest) {
	return http.post("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings", recurringRequest);
}

async function updateRecurring(accountId, stockingId, recurringId, changes, modificationDate) {
	if (modificationDate) {
		const from = Format.isodate(modificationDate);
		return http.patch("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings/" + recurringId + "?from=" + from, changes);
	} else {
		return http.patch("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings/" + recurringId, changes);
	}
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const recurringService = {
	intervalTypes,
	getRecurrings,
	createRecurring,
	updateRecurring,
	handleExpectedErrors,
};

export default recurringService;
