import React, { Component } from "react";
import ListCardEntry from "./listCardEntry";
import { Link } from "react-router-dom";

class ListCard extends Component {
	state = { editing: false };

	getSpanStyles = () => {
		return { lineHeight: "18px", verticalAlign: "text-bottom" };
	};

	getSpanClasses = () => {
		return "text-primary text-decoration-underline small clickable";
	};

	render() {
		const { editing } = this.state;
		const { title, items, link, onEdit } = this.props;
		const height = { height: 15 };

		return (
			<div className="card shadow align-self-start">
				<div className="card-header">
					<span className="d-none d-lg-inline-block me-3 h5" style={height}>
						{title}
					</span>
					<span className="d-inline-block d-lg-none me-2 fw-bold fs-6" style={height}>
						{title}
					</span>

					{/* Edit Link */}
					{onEdit && (
						<span className={this.getSpanClasses()} style={this.getSpanStyles()} onClick={() => this.setState({ editing: !editing })}>
							Bearbeiten
						</span>
					)}

					{/* Function Link */}
					{link && link.onClick && (
						<span className={this.getSpanClasses()} style={this.getSpanStyles()} onClick={link.onClick}>
							{link.label}
						</span>
					)}

					{/* Href Link */}
					{link && link.to && (
						<Link className="small ms-1" to={link.to}>
							{link.label}
						</Link>
					)}
				</div>

				<ul className="list-group list-group-flush">
					{items
						.filter((item) => item.value !== undefined)
						.map((item) => {
							const { label, editable, ...rest } = item;
							return <ListCardEntry key={label} label={label} onEdit={onEdit} edit={editable && editing} {...rest} />;
						})}
				</ul>
			</div>
		);
	}
}

export default ListCard;
